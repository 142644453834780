<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Exportar Aula</span>
          <v-spacer></v-spacer>
          <v-btn style="color: #fff" class="red darken-1" @click="$emit('dialogChange', false)">
            fechar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(enableSubmit)" :disabled="false">
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">Turma</v-stepper-step>
                    <v-stepper-step :complete="step > 2" step="2">Tipo de Aula</v-stepper-step>
                    <v-stepper-step :complete="step > 3" step="3">Data e Horário</v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row>
                        <v-col class="pt-4 pb-0" cols="12">
                          <e-label class="font-weight-medium">Turma</e-label>
                          <p class="font-weight-regular">
                            <v-select
                              :items="turmas"
                              item-text="turma.descricao"
                              item-value="turma.id"
                              :hint="`${turmaSelecionada.turma.franquia.descricao}`"
                              return-object
                              label="Selecione o campo abaixo"
                              v-model="turmaSelecionada"
                            ></v-select>
                          </p>
                        </v-col>
                      </v-row>
                      <v-col class="pt-4 pb-4" cols="12">
                        <v-row class="justify-space-between">
                          <v-col cols="5">
                            <v-btn class="mt-4 mr-1" color="primary" @click="step = 2" block>
                              <v-icon dark left>mdi-arrow-right</v-icon> Próximo
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-row>
                        <v-col class="pt-4 pb-0" cols="12">
                          <e-label class="font-weight-medium">Tipo de Aula</e-label>
                          <p class="font-weight-regular">{{ gestaoDeAula.tipo_de_aula }}</p>
                        </v-col>
                      </v-row>
                      <v-col class="pt-4 pb-4" cols="12">
                        <v-row class="justify-space-between">
                          <v-col cols="5">
                            <v-btn class="mt-4 mr-1" color="secondary" @click="step = 1" block>
                              <v-icon dark left>mdi-arrow-left</v-icon> Anterior
                            </v-btn>
                          </v-col>
                          <v-col cols="5">
                            <v-btn class="mt-4" color="primary" @click="step = 3" block>
                              <v-icon dark left>mdi-arrow-right</v-icon> Próximo
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-row>
                        <v-col class="pt-4 pb-0" :cols="12" :sm="4">
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <e-label>Selecione uma data</e-label>
                              <v-text-field
                                dense
                                solo
                                v-model="data_aula"
                                label="data"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="dataAulaDuplicada" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.dialog.save(date)"
                                >OK</v-btn
                              >
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col
                          class="pt-4 pb-0"
                          v-if="gestaoDeAula.tipo_de_aula !== 'Aula Remota'"
                          cols="6"
                        >
                          <ValidationProvider
                            name="Horário da aula"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Selecione um horário</e-label>
                            <e-autocomplete
                              :items="horarios"
                              :error-messages="errors"
                              label="Selecione uma opção"
                              :item-text="(item) => item.descricao"
                              v-model="horarioSelecionado"
                              return-object
                              dense
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row v-if="!gestaoDeAula.e_aula_infantil">
                        <v-col class="pt-4 pb-0" cols="12">
                          <header>
                            <h2 class="font-weight-medium">Resumo</h2>
                          </header>
                          <section>
                            <div>
                              <e-label class="font-weight-medium">Escola</e-label>
                              <p class="font-weight-regular">
                                {{ turmaSelecionada.turma.franquia.descricao }}
                              </p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Turma</e-label>
                              <p class="font-weight-regular">
                                {{ turmaSelecionada.turma.descricao }}
                              </p>
                            </div>
                          </section>
                          <section>
                            <div>
                              <e-label class="font-weight-medium">Tipo de Aula</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.tipo_de_aula }}</p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Conteúdo</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.conteudo }}</p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Metodologia</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.metodologia }}</p>
                            </div>
                          </section>
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col class="pt-4 pb-0" cols="12">
                          <header>
                            <h2 class="font-weight-medium">Resumo</h2>
                          </header>
                          <section>
                            <div>
                              <e-label class="font-weight-medium">Escola</e-label>
                              <p class="font-weight-regular">
                                {{ turmaSelecionada.turma.franquia.descricao }}
                              </p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Turma</e-label>
                              <p class="font-weight-regular">
                                {{ turmaSelecionada.turma.descricao }}
                              </p>
                            </div>
                          </section>
                          <section>
                            <div>
                              <e-label class="font-weight-medium">Tipo de Aula</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.tipo_de_aula }}</p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Campos de experiência</e-label>
                              <ul class="font-weight-regular">
                                <li
                                  v-for="campo in gestaoDeAula.campos_de_experiencias"
                                  :key="campo"
                                >
                                  {{ campo }}
                                </li>
                              </ul>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Temas integrados</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.eixos }}</p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium"
                                >Possibilidades pedagógicas</e-label
                              >
                              <p class="font-weight-regular">{{ gestaoDeAula.estrategias }}</p>
                            </div>
                            <div>
                              <e-label class="font-weight-medium">Observações</e-label>
                              <p class="font-weight-regular">{{ gestaoDeAula.observacoes }}</p>
                            </div>
                          </section>
                        </v-col>
                      </v-row>

                      <v-col class="pt-4 pb-4" cols="12">
                        <v-row class="justify-space-between">
                          <v-col cols="5">
                            <v-btn class="mt-4 mr-1" color="secondary" @click="step = 2" block>
                              <v-icon dark left>mdi-arrow-left</v-icon> Anterior
                            </v-btn>
                          </v-col>
                          <v-col cols="5">
                            <v-btn class="mt-4" color="primary" type="submit" block>
                              <v-icon dark left>mdi-checkbox-marked-circle</v-icon> Salvar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="exportarAula"
    ></e-modal-confirm>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    submittingForm: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      require: true,
    },
    // datasPermitidas: {
    //   type: Array,
    //   require: true,
    // },
    // horariosPermidos: {
    //   type: Array,
    //   require: true,
    // },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      step: 1,
      dataAulaDuplicada: null,
      modal: false,
      menu: false,
      gestaoDeAula: {
        disciplinas_com_descricao: [],
        conteudo_polivalencia: "",
      },
      data_aula: null,
      confirmDialog: false,
      turmas: [],
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      objectModal: {
        title: "Exportar aula",
        message: "Tem certeza que deseja exportar esta aula?",
      },
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      horarios: [],
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        this.gestaoDeAula = val;
      },
    },
    turmaSelecionada: {
      immediate: true,
      handler(val) {
        this.horarios = val.turma.horarios;
      },
    },
    dialog: {
      async handler(val) {
        if (val) {
          this.$loaderService.open("Carregando gestões de aula");
          try {
            const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
              this.gestaoDeAula
            );
            console.log(gestoes);

            this.turmas = gestoes;
          } catch (error) {
            this.$handleError(error);
          } finally {
            this.$loaderService.close();
          }
        }
      },
    },
    dataAulaDuplicada(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(date);
      this.data_aula = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
      this.gestaoDeAula.data = this.dataAulaDuplicada;
    },
    async exportarAula() {
      this.$loaderService.open("Exportando a Aula");
      try {
        this.gestaoDeAula.horario_inicial = this.horarioSelecionado
          ? this.horarioSelecionado.inicio
          : null;
        this.gestaoDeAula.horario_final = this.horarioSelecionado
          ? this.horarioSelecionado.final
          : null;
        this.gestaoDeAula.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
        this.gestaoDeAula.instrutorDisciplinaTurma_id = parseInt(this.turmaSelecionada.id, 10);
        this.gestaoDeAula.turma_id = this.turmaSelecionada.turma.id;
        this.confirmDialog = !this.confirmDialog;
        this.$emit("dialogChange", false);
        await this.$services.aulasService.criar(this.gestaoDeAula);
        this.$toast.success("Aula exportada com sucesso!");
        this.$router.push({ name: "aulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    parseConteudoPolivalencia(conteudoPolivalencia) {
      try {
        return JSON.parse(conteudoPolivalencia);
      } catch (e) {
        return [];
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    // async getHorarios() {
    //   const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
    //     this.$route.params.gestaoDeAulaId
    //   );
    //   this.gestaoDeAula = response.payload.gestaoDeAula;

    //   if (this.gestaoDeAula.relacoesDiasHorarios) {
    //     this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
    //       (g) => g.dia.id
    //     );

    //     this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
    //     this.horariosSabado = this.horarios;
    //     this.horariosSabado = this.horariosSabado.map((h) => {
    //       return {
    //         id: h.id,
    //         turno_id: h.turno_id,
    //         descricao: h.descricao.split(" - ")[0],
    //         final: h.final,
    //         inicio: h.inicio,
    //       };
    //     });
    //   }
    //   this.horariosDoComponente =
    //     new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;

    //   // this.horarios = response.payload.horarios.filter((horario) => {
    //   //   return horario.descricao.toLowerCase().includes("momento");
    //   // });
    // },
    async loadData() {
      this.aulaSelecionada = await this.getAula();
    },
    async getAula() {
      await this.getHorarios();
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.turma = response.payload.gestaoDeAula.turma;
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios.filter((horario) => {
        // console.log(this.diasPermitidosParaCriarAula);

        if (this.gestaoDeAula.is_polivalencia) {
          return horario.descricao;
        }
        return horario.descricao;
      });
      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));
      this.horarioSelecionado = aulaSelecionada.horario ?? null;

      this.text = aulaSelecionada.conteudo;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);

        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
        this.horariosDoComponente =
          new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;
      }

      return aulaSelecionada;
    },
    async getHorarios() {
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
      }
      this.horariosDoComponente =
        new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;

      // this.horarios = response.payload.horarios.filter((horario) => {
      //   return horario.descricao.toLowerCase().includes("momento");
      // });
    },
  },
};
</script>
