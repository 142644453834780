<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item :class="localAula.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Professor (a)</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.professor?.nome }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">N° da semana</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.numero_da_semana }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Mês</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.mes }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Data</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.data_formatada }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Tema da Aula</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.tema_hora ?? "--:--" }} / {{ localAula.tema_da_aula }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4"
          >Acolhida (contos de desafios, brinquedos ou jogos)
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.acolhida_hora ?? "--:--" }} / {{ localAula.acolhida }}
        </p>

        <v-divider></v-divider>
        <div v-for="(item, index) of $constants.listCamposRotinasLancadas" :key="index">
          <div v-if="index !== 'rotinas'">
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-5">{{
              item
            }}</v-list-item-title>
            <div v-if="localAula[index]" class="font-weight-light text-justify">
              <div
                v-html="formatTexto(localAula[index]) ? formatTexto(localAula[index]) : '- - -'"
              ></div>
            </div>
          </div>
          <div v-else>
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-2"
              >{{ item }}
            </v-list-item-title>
            <p class="font-weight-light text-justify">
              ({{ localAula.rotina_diaria_hora ?? "--:--" }})
            </p>
            <div v-for="(el, index) of localAula[index]" :key="index">
              <p v-if="el" :key="index" class="font-weight-light text-justify">•{{ el }}</p>
            </div>
          </div>
        </div>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Roda de História </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.roda_historia_hora ?? "--:--" }} / {{ localAula.roda_historia }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Lanche </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.lanche_hora ?? "--:--" }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Recreação </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.recreacao_hora ?? "--:--" }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Roda de conversa </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.roda_conversa_hora ?? "--:--" }} / {{ localAula.roda_conversa }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Possibilidades Pedagógicas </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.possibilidades_hora ?? "--:--" }} /
          {{ localAula.possibilidades_pedagogicas }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Hora da Música </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.hora_musica ?? "--:--" }} / {{ localAula.hora_da_musica }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Almoço </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.almoco_hora ?? "--:--" }}
        </p>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Encerramento da aula </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.encerramento_aula }}
        </p>

        <v-divider></v-divider>
        <div v-for="(item, index) of $constants.listCamposAulasLancadas" :key="index">
          <div v-if="index !== 'campos_de_experiencias'">
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-5">{{
              item
            }}</v-list-item-title>
            <div v-if="localAula[index]" class="font-weight-light text-justify">
              <div
                v-html="formatTexto(localAula[index]) ? formatTexto(localAula[index]) : '- - -'"
              ></div>
            </div>
          </div>
          <div v-else>
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-2">{{
              item
            }}</v-list-item-title>
            <div v-for="(el, index) of localAula[index]" :key="index">
              <p v-if="el" :key="index" class="font-weight-light text-justify">•{{ el }}</p>
            </div>
          </div>
        </div>
        <v-divider></v-divider>

        <v-divider></v-divider>
        <v-list-item-title class="mt-4"
          >Objeto de aprendizagem e desenvolvimento
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.objeto_aprendizagem }}
        </p>

        <v-divider></v-divider>
        <div v-for="(item, index) of $constants.listCamposRotinasDireiro" :key="index">
          <div v-if="index !== 'direito_aprendizagem'">
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-5">{{
              item
            }}</v-list-item-title>
            <div v-if="localAula[index]" class="font-weight-light text-justify">
              <div
                v-html="formatTexto(localAula[index]) ? formatTexto(localAula[index]) : '- - -'"
              ></div>
            </div>
          </div>
          <div v-else>
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-2">{{
              item
            }}</v-list-item-title>
            <div v-for="(el, index) of localAula[index]" :key="index">
              <p v-if="el" :key="index" class="font-weight-light text-justify">•{{ el }}</p>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatTexto(texto) {
      const textoSemColchetes = texto.replace(/\[|\]/g, ""); // Remover colchetes
      const textoSemQuebraDeLinha = textoSemColchetes.replace(/\\n/g, " ").replace(/"/g, ""); // Remover caracteres de nova linha (\n) e aspas
      const textoFormatado = textoSemQuebraDeLinha.replace(/\n/g, "<br>"); // Substituir quebras de linha por HTML <br>
      return textoFormatado.trim() || "- - -";
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
    };
  },
};
</script>

<style lang="scss" scoped></style>
