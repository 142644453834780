<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'professores' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Quadro de Horário Professor(a):
          {{ professor ? professor?.nome : "[NÃO INFORMADO]" }}
        </h1>

        <div v-if="gestoesDeAulas.length === 0" style="text-align: center">
          <v-alert color="warning" icon="fa fa-frown-o"
            >Nenhum Quadro de Horário disponível para este professor.</v-alert
          >
        </div>

        <v-simple-table v-else>
          <thead>
            <tr>
              <th style="text-align: center">Dia/Horário</th>
              <th v-for="horario in horariosUnicos" :key="horario.id" style="text-align: center">
                {{ horario.descricao }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dia, index) in diasUnicos" :key="index">
              <td>{{ dia.descricao.slice(0, 3) }}</td>
              <td
                v-for="(horario, i) in horariosUnicos"
                :key="i"
                :style="{
                  backgroundColor: getCorPorTurma(
                    getTurmaDisciplina(dia.id, horario.id)?.turma || 'INDEFINIDA'
                  ),
                }"
              >
                <div v-if="getTurmaDisciplina(dia.id, horario.id)">
                  {{ getTurmaDisciplina(dia.id, horario.id).disciplina }}<br />
                  {{ getTurmaDisciplina(dia.id, horario.id).turma }}
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      professor: [],
      gestoesDeAulas: [],
      horariosUnicos: [],
      diasDaSemana: [
        { id: 1, descricao: "Segunda" },
        { id: 2, descricao: "Terça" },
        { id: 3, descricao: "Quarta" },
        { id: 4, descricao: "Quinta" },
        { id: 5, descricao: "Sexta" },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.$loaderService.open("Carregando Professor");
        const { gestaoDeAulaId } = this.$route.params;
        const response = await this.$services.gestoesDeAulasService.quadroHorario(gestaoDeAulaId);

        this.professor = response.professor || {};
        this.gestoesDeAulas = response;

        this.processarGestaoDeAulas();
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },

    processarGestaoDeAulas() {
      const diasDaSemana = [
        { id: 0, descricao: "Segunda" },
        { id: 1, descricao: "Terça" },
        { id: 2, descricao: "Quarta" },
        { id: 3, descricao: "Quinta" },
        { id: 4, descricao: "Sexta" },
      ];

      const dias = new Map();
      const horarios = new Map();

      if (this.gestoesDeAulas.relacoesDiasHorarios) {
        this.gestoesDeAulas.relacoesDiasHorarios.forEach((relacao) => {
          if (!dias.has(relacao.dia.id)) {
            dias.set(relacao.dia.id, { id: relacao.dia.id, descricao: relacao.dia.descricao });
          }

          if (!horarios.has(relacao.horario.id)) {
            horarios.set(relacao.horario.id, {
              id: relacao.horario.id,
              descricao: relacao.horario.descricao,
            });
          }
        });
      }

      diasDaSemana.forEach((diaDaSemana) => {
        if (!dias.has(diaDaSemana.id)) {
          dias.set(diaDaSemana.id, diaDaSemana);
        }
      });

      this.diasUnicos = Array.from(dias.values()).sort((a, b) => a.id - b.id);
      this.horariosUnicos = Array.from(horarios.values()).sort((a, b) => a.id - b.id);
    },

    getTurmaDisciplina(diaId, horarioId) {
      if (!this.gestoesDeAulas || !this.gestoesDeAulas.relacoesDiasHorarios) return null;

      const relacao = this.gestoesDeAulas.relacoesDiasHorarios.find(
        (r) => r.dia.id === diaId && r.horario.id === horarioId
      );

      return relacao
        ? {
            turma: this.gestoesDeAulas.turma?.descricao || "Turma não definida",
            disciplina: this.gestoesDeAulas.disciplina?.descricao || "Disciplina não definida",
          }
        : null;
    },

    getCorPorTurma(turma) {
      const corPorTurma = {
        CRECHE: "#FFB3BA",
        PRE: "#FFDFBA",
        "PRÉ ESCOLAR": "#FFDFBA",
        "1º ANO": "#FFFFBA",
        "2º ANO": "#BAFFC9",
        "3º ANO": "#BAE1FF",
        "4º ANO": "#FFABAB",
        "5º ANO": "#FFC3A0",
        "6º ANO": "#D9BF77",
        "7º ANO": "#F6B93B",
        "8º ANO": "#12CBC4",
        "9º ANO": "#D980FA",
        MULTI: "#FF6F61",
      };

      const corEncontrada = Object.keys(corPorTurma).find((key) => turma.includes(key));

      return corEncontrada ? corPorTurma[corEncontrada] : "#ffffff";
    },
  },
};
</script>

<style scoped>
th {
  text-align: center;
  background-color: #e0e0e0;
  padding: 10px;
}
td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
}
</style>
