<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'rotinasInfantis',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Rotina
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="N° da semana" rules="required" v-slot="{ errors }">
              <e-label>N° da semana</e-label>
              <e-autocomplete
                :items="$constants.numeroDaSemana"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.numero_da_semana"
                label="Selecione uma semana"
                dense
                solo
                item-text="name"
                disabled
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Mês" rules="required" v-slot="{ errors }">
              <e-label>Mês</e-label>
              <e-autocomplete
                :items="$constants.meses"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.mes"
                label="Selecione um mês"
                dense
                solo
                item-text="descricao"
                disabled
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="aulaSelecionada.data"
                  persistent-hint
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" :allowed-dates="allowedDates" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center pt-2">
            <template>
              <v-toolbar>
                <v-tabs dark v-model="activeTab" background-color="primary" grow>
                  <v-tab
                    v-for="item of $constants.semanas"
                    :key="item.value"
                    disabled
                    :style="{ color: 'black' }"
                  >
                    <v-badge v-if="form.semana === item.value" color="black">
                      {{ item.desc }}
                    </v-badge>
                    <div v-else>{{ item.desc }}</div>
                  </v-tab>
                </v-tabs>
              </v-toolbar>
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <e-label>Tema da Aula</e-label>
            <ValidationProvider name="Tema da Aula Hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuTemaHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.tema_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Tema da Aula)"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.tema_hora"
                  :disabled="!aulaSelecionada.data"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <ValidationProvider name="Tema da Aula" rules="required" v-slot="{ errors }">
              <v-textarea
                v-model="aulaSelecionada.tema_da_aula"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
                solo
                dense
              ></v-textarea>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <e-label>Acolhida (contos de desafios, brinquedos ou jogos) </e-label>
            <ValidationProvider name="acolhida_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuAcolhidaHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.acolhida_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Acolhida (contos de desafios, brinquedos ou jogos) )"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.acolhida_hora"
                  :disabled="!aulaSelecionada.data"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <ValidationProvider name="Acolhida" rules="required" v-slot="{ errors }">
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.acolhida"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <h4>Rotina diária</h4>

            <ValidationProvider name="rotina_diaria_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuRotinaDiariaHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.rotina_diaria_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Rotina diária)"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.rotina_diaria_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <v-row class="pt-0 pb-0">
              <v-col class="pt-0 pb-0" cols="3" v-for="(el, index) of rotinasOpcoes" :key="index">
                <v-checkbox
                  v-model="rotinas[index]"
                  :disabled="!aulaSelecionada.data"
                  :label="el"
                  :value="el"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <e-label>Roda de História </e-label>

            <ValidationProvider name="roda_historia_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuRodaHistoriaHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.roda_historia_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Roda de História )"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.roda_historia_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
            <ValidationProvider name="Roda de História" rules="required" v-slot="{ errors }">
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.roda_historia"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <e-label>Lanche </e-label>

            <ValidationProvider name="lanche_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuLancheHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.lanche_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Lanche)"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.lanche_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <e-label>Recreação </e-label>

            <ValidationProvider name="recreacao_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuRecreacaoHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.recreacao_hora"
                    :disabled="!aulaSelecionada.data"
                    label="Hora (Recreação)"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.recreacao_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <e-label>Roda de conversa </e-label>

            <ValidationProvider name="roda_conversa_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuRodaConversaHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.roda_conversa_hora"
                    label="Hora (Roda de conversa)"
                    :disabled="!aulaSelecionada.data"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.roda_conversa_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
            <ValidationProvider name="Roda de Conversa" rules="required" v-slot="{ errors }">
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.roda_conversa"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <e-label>Possibilidades Pedagógicas </e-label>
            <ValidationProvider name="possibilidades_hora" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuPossibilidadesHora"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.possibilidades_hora"
                    label="Hora (Possibilidades Pedagógicas )"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :disabled="!aulaSelecionada.data"
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.possibilidades_hora"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
            <ValidationProvider name="Possibilidades" rules="required" v-slot="{ errors }">
              <v-textarea
                solo
                dense
                :disabled="!aulaSelecionada.data"
                v-model="aulaSelecionada.possibilidades_pedagogicas"
                :error-messages="errors"
              />
            </ValidationProvider>

            <e-label>Hora da Música </e-label>
            <ValidationProvider name="hora_musica" rules="required" v-slot="{ errors }">
              <v-menu
                v-model="menuHoraMusica"
                :close-on-content-click="false"
                min-width="290px"
                transition="scale-transition"
                offset-y
                @open="focusOnMinutes"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="aulaSelecionada.hora_musica"
                    label="Hora (Hora da Música)"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :disabled="!aulaSelecionada.data"
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timePicker"
                  v-model="aulaSelecionada.hora_musica"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </ValidationProvider>
            <ValidationProvider name="Hora da Música" rules="required" v-slot="{ errors }">
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.hora_da_musica"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
              />
            </ValidationProvider>

            <v-col cols="12" class="pt-0 pb-0">
              <e-label>Almoço </e-label>
              <ValidationProvider name="almoco_hora" rules="required" v-slot="{ errors }">
                <v-menu
                  v-model="menuAlmocoHora"
                  :close-on-content-click="false"
                  min-width="290px"
                  transition="scale-transition"
                  offset-y
                  @open="focusOnMinutes"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="aulaSelecionada.almoco_hora"
                      label="Hora (Almoço)"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="!aulaSelecionada.data"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    ref="timePicker"
                    v-model="aulaSelecionada.almoco_hora"
                    full-width
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" class="pt-0 pb-0">
              <e-label>Encerramento da aula </e-label>

              <ValidationProvider name="Encerramento da aula" rules="required" v-slot="{ errors }">
                <v-menu
                  v-model="menuEncerramento"
                  :close-on-content-click="false"
                  min-width="290px"
                  transition="scale-transition"
                  offset-y
                  @open="focusOnMinutes"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="aulaSelecionada.encerramento_aula"
                      label="Hora (Encerramento da aula )"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!aulaSelecionada.data"
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :disabled="!aulaSelecionada.data"
                    ref="timePicker"
                    v-model="aulaSelecionada.encerramento_aula"
                    full-width
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </ValidationProvider>
            </v-col>

            <v-col class="pt-0 pb-0" cols="12">
              <h4>Campos de experiencias</h4>
              <v-row class="">
                <v-col class="pt-0 pb-0" cols="4" v-for="(el, index) of opcoes" :key="index">
                  <v-checkbox
                    v-model="campos_de_experiencias[index]"
                    :disabled="!aulaSelecionada.data"
                    :label="el"
                    :value="el"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <ValidationProvider name="Objeto de aprendizagem" rules="required" v-slot="{ errors }">
              <e-label>Objeto de aprendizagem e desenvolvimento </e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.objeto_aprendizagem"
                :disabled="!aulaSelecionada.data"
                :error-messages="errors"
              />
            </ValidationProvider>

            <v-col cols="12">
              <h4>Direito de Aprendizagem</h4>
              <v-row class="">
                <v-col class="pt-0 pb-0" cols="4" v-for="(el, index) of direitoOpcoes" :key="index">
                  <v-checkbox
                    v-model="direito_aprendizagem[index]"
                    :disabled="!aulaSelecionada.data"
                    :label="el"
                    :value="el"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <!-- <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.duplicar = true"
              >Duplicar Aula</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.exportar = true"
              >Exportar Aula</v-btn
            > -->
          </v-col>
        </v-row>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
    week: {
      type: String,
      default: null,
    },
    month: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    } else if (this.week && this.month) {
      // Se semana e mês foram passados como props
      this.mostrarCampos = true;
      this.aulaSelecionada.numero_da_semana = this.week;
      this.aulaSelecionada.mes = this.month;
      this.buscarAulaExistenteOnLoad();
    }

    if (this.aulaSelecionada.data) {
      const weekDay = this.getWeekDay(new Date(this.aulaSelecionada.data));
      this.activeTab = weekDay; // Define a aba ativa com base no dia da semana
    }
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      rotinasOpcoes: ["chamada", "aniversariante do dia", "cartaz do tempo", "quantos somos"],
      direitoOpcoes: ["Conviver", "Participar", "Expressar", "Brincar", "Explorar", "Conhecer-se"],
      data: null,
      menuTemaHora: false,
      menuAcolhidaHora: false,
      menuRotinaDiariaHora: false,
      menuLancheHora: false,
      menuRodaHistoriaHora: false,
      menuRecreacaoHora: false,
      menuRodaConversaHora: false,
      menuPossibilidadesHora: false,
      menuHoraMusica: false,
      menuAlmocoHora: false,
      menuEncerramento: false,
      modal: false,
      dataSelecionada: null,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      diasSemana: this.$constants.diasDaSemana,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      campos_de_experiencias: [],
      direito_aprendizagem: [],
      rotinas: [],
      aulaSelecionada: {
        data: null,
        dia_da_semana: "",
        numero_da_semana: this.week,
        mes: this.month,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarios_infantis: [],
      activeTab: 0,
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),

    mostrarCampos() {
      return this.aulaSelecionada.numero_da_semana && this.aulaSelecionada.mes;
    },
    allowedDates(date) {
      if (!this.aulaSelecionada.mes || !this.aulaSelecionada.numero_da_semana) {
        return () => true;
      }

      const monthMap = {
        Janeiro: 0,
        Fevereiro: 1,
        Março: 2,
        Abril: 3,
        Maio: 4,
        Junho: 5,
        Julho: 6,
        Agosto: 7,
        Setembro: 8,
        Outubro: 9,
        Novembro: 10,
        Dezembro: 11,
      };

      const selectedWeek = parseInt(this.aulaSelecionada.numero_da_semana, 10);
      const selectedMonth = monthMap[this.aulaSelecionada.mes];

      // Obtém o ano do usuário logado
      const year = this.user?.ano?.descricao || new Date().getFullYear();

      // Primeiro e último dias do mês
      const firstDayOfMonth = new Date(year, selectedMonth, 0);
      // Último dia do mês selecionado
      const lastDayOfMonth = new Date(year, selectedMonth + 1, 0);
      const arredondamento = this.getArredondamento(lastDayOfMonth.getDate());

      // Calcula o primeiro dia da semana selecionada
      const firstDayOfSelectedWeek = new Date(firstDayOfMonth);
      let weekCounter = 1;

      while (weekCounter < selectedWeek) {
        firstDayOfSelectedWeek.setDate(
          firstDayOfSelectedWeek.getDate() +
            lastDayOfMonth.getDate() / this.$constants.quantidadeDeSemanasDeUmMes +
            arredondamento
        );
        weekCounter++;
      }

      // Verifica se firstDayOfSelectedWeek ultrapassa o último dia do mês
      if (firstDayOfSelectedWeek > lastDayOfMonth) {
        return () => false; // Retorna falso se a semana selecionada não existe no mês
      }

      // Calcula o último dia da semana selecionada
      const lastDayOfSelectedWeek = new Date(firstDayOfSelectedWeek);
      lastDayOfSelectedWeek.setDate(
        lastDayOfSelectedWeek.getDate() +
          lastDayOfMonth.getDate() / this.$constants.quantidadeDeSemanasDeUmMes +
          arredondamento
      );

      // Se for a última semana do mês, adiciona todos os dias restantes do mês
      if (selectedWeek === 4) {
        lastDayOfSelectedWeek.setDate(
          lastDayOfSelectedWeek.getDate() +
            lastDayOfMonth.getDate() -
            lastDayOfSelectedWeek.getDate()
        );
      }

      // Retorna a função de filtro para o componente VDatePicker
      return function (d) {
        const currentDate = new Date(d);
        // Verifica se a data está dentro da semana e dentro do mês selecionado
        return (
          currentDate >= firstDayOfSelectedWeek &&
          currentDate <= lastDayOfSelectedWeek &&
          currentDate >= firstDayOfMonth &&
          currentDate <= lastDayOfMonth &&
          currentDate.getDay() !== 5 && // Domingo
          currentDate.getDay() !== 6 // Sábado
        );
      };
    },
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== null) {
        const formattedDate = new Intl.DateTimeFormat("pt-BR", {
          timeZone: "UTC",
        }).format(new Date(newVal));

        const weekDayValue = this.getWeekDay(newVal);
        this.aulaSelecionada.data = weekDayValue;
        this.form.semana = weekDayValue;
        this.aulaSelecionada.data = formattedDate;
        this.updateActiveTab();
        this.buscarAulaExistenteOnLoad();
      }
    },
    week(newVal, oldVal) {
      if (newVal && this.aulaSelecionada.mes && this.data) {
        this.buscarAulaExistenteOnLoad();
      }
    },
    month(newVal, oldVal) {
      if (newVal && this.aulaSelecionada.numero_da_semana && this.data) {
        this.buscarAulaExistenteOnLoad();
      }
    },
  },
  methods: {
    getArredondamento(lastDayOfMonth) {
      switch (lastDayOfMonth) {
        case 31:
          return 1;
        case 30:
          return 1;
        case 29:
          return 1;
        case 28:
          return 0;
        default:
          return 0;
      }
    },
    focusOnMinutes() {
      this.$nextTick(() => {
        const timePicker = this.$refs.timePicker;
        if (timePicker) {
          const minuteInput = timePicker.$el.querySelector(".v-time-picker-clock__item--active");
          if (minuteInput) {
            minuteInput.focus();
          }
        }
      });
    },
    updateActiveTab() {
      if (this.aulaSelecionada.data) {
        const weekDay = this.getWeekDay(new Date(this.aulaSelecionada.data));
        this.activeTab = weekDay; // Atualiza a aba ativa com base no dia da semana
      }
    },
    async buscarAulaExistenteOnLoad() {
      try {
        if (!this.data) return;

        const response = await this.$services.rotinasService.buscarAulaExistente({
          gestaoId: this.$route.params.gestaoDeAulaId,
          week: this.aulaSelecionada.numero_da_semana,
          month: this.aulaSelecionada.mes,
          data: this.data,
        });

        if (response) {
          // Mesclar os dados retornados com os existentes
          this.aulaSelecionada = {
            ...this.aulaSelecionada,
            ...response,
          };

          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
          this.campos_de_experiencias = this.aulaSelecionada.campos_de_experiencias || [];
          this.direito_aprendizagem = this.aulaSelecionada.direito_aprendizagem || [];
          this.rotinas = this.aulaSelecionada.rotinas || [];
        } else {
          this.campos_de_experiencias = this.campos_de_experiencias || [];
          this.direito_aprendizagem = this.direito_aprendizagem || [];
          this.rotinas = this.rotinas || [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    getWeekDay(date) {
      const weekDays = {
        0: 0, // Segunda-feira
        1: 1, // Terça-feira
        2: 2, // Quarta-feira
        3: 3, // Quinta-feira
        4: 4, // Sexta-feira
      };
      const day = new Date(date).getDay();
      return weekDays[day];
    },
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data;
      const aula_id = this.$route.params.aula_id;
      const horario_id = this.horarioSelecionado.id;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

      const response = await this.$services.rotinasService.pesquisarAulasEmConflitos(
        data_aula,
        horario_id,
        turma_id,
        aula_id
      );

      if (response.data.length <= 0) {
        this.outrosCampos = true;
      }

      if (response.data.length > 0) {
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
        this.aulasConflitadas = response.data;
      }
    },

    async verificaTipoDaAula() {
      this.getAula();
      this.outrosCampos = true;
    },

    async submitForm() {
      this.aulaSelecionada.horarios_infantis = this.horarios_infantis;
      this.aulaSelecionada.e_aula_infantil = 1;
      this.aulaSelecionada.campos_de_experiencias = this.campos_de_experiencias;
      this.aulaSelecionada.rotinas = this.rotinas;
      this.aulaSelecionada.direito_aprendizagem = this.direito_aprendizagem;
      this.submittingForm = true;
      try {
        this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
          this.$route.params.gestaoDeAulaId,
          10
        );
        this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");

        if (this.editing) {
          const originalAula = await this.$services.rotinasService.getAula(this.aulaSelecionada.id);
          if (this.aulaSelecionada.data !== originalAula.data) {
            this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
            this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
            this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
            await this.$services.rotinasService.criarAula(this.aulaSelecionada);
            this.$toast.success("Rotina editada com sucesso!");
          } else {
            await this.$services.rotinasService.atualizar(this.aulaSelecionada);
            this.$toast.success("Rotina editada com sucesso!");
          }
        } else {
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          this.aulaSelecionada.semana = this.form.semana;
          await this.$services.rotinasService.criarAula(this.aulaSelecionada);
          this.$toast.success("Rotina cadastrada com sucesso!");
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
      this.$router.push({ name: "rotinasInfantis" });
    },

    async getAula() {
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );

      this.gestaoDeAula = response.payload.gestaoDeAula;
      const aulaSelecionada = await this.$services.rotinasService.getAula(parseInt(aula_id, 10));
      this.horarios_infantis = aulaSelecionada.horarios_infantis;
      this.campos_de_experiencias = aulaSelecionada.campos_de_experiencias
        ? aulaSelecionada.campos_de_experiencias
        : [];

      this.direito_aprendizagem = aulaSelecionada.direito_aprendizagem
        ? aulaSelecionada.direito_aprendizagem
        : [];

      this.rotinas = aulaSelecionada.rotinas ? aulaSelecionada.rotinas : [];
      return aulaSelecionada;
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const aulaData = await this.getAula();
        this.aulaSelecionada = { ...this.aulaSelecionada, ...aulaData };
        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
          // Definir a aba ativa com base no dia da semana
          const weekDay = this.getWeekDay(
            new Date(this.aulaSelecionada.data.split("/").reverse().join("-"))
          );
          this.activeTab = weekDay;
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    diaDaSemana() {
      const data_do_usuario = this.aulaSelecionada.data;
      const data_do_usuario_formatada = data_do_usuario.replace(/\//g, "-");
      const [dia, mes, ano] = data_do_usuario_formatada.split("-");
      const dataFormatada = `${ano}-${mes}-${dia}`;
      const diaDaSemana = new Date(dataFormatada).getDay();
      return this.diasSemana[diaDaSemana];
    },
  },
};
</script>

<style scoped>
.v-tabs__item--active {
  background-color: #3f51b5; /* Cor de fundo quando selecionado */
  color: white; /* Cor do texto quando selecionado */
}
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
