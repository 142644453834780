import { render, staticRenderFns } from "./NewCreateRotinaInfantil.vue?vue&type=template&id=2b8cc7e8&scoped=true"
import script from "./NewCreateRotinaInfantil.vue?vue&type=script&lang=js"
export * from "./NewCreateRotinaInfantil.vue?vue&type=script&lang=js"
import style0 from "./NewCreateRotinaInfantil.vue?vue&type=style&index=0&id=2b8cc7e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8cc7e8",
  null
  
)

export default component.exports