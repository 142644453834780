<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item :class="localAula.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-icon left>mdi-account</v-icon>
          Professor (a)
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.gestao.professor?.nome }}
        </p>
        <v-divider></v-divider>

        <v-list-item-title class="mt-4">
          <v-icon left>mdi-calendar</v-icon>
          Data
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.data }}
        </p>

        <v-list-item-title class="mt-4">
          <v-icon left>mdi-clock-start</v-icon>
          HORÁRIO DE INÍCIO
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.horario_inicio }}
        </p>

        <v-list-item-title class="mt-4">
          <v-icon left>mdi-clock-end</v-icon>
          HORÁRIO DE FIM
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.horario_fim }}
        </p>
        <v-divider></v-divider>

        <v-list-item-title class="mt-4">
          <v-icon left>mdi-target</v-icon>
          OBJETIVOS DE APRENDIZAGEM
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.metas }}
        </p>
        <v-divider></v-divider>

        <v-list-item-title class="mt-4">
          <v-icon left>mdi-book-open</v-icon>
          DESCRIÇÃO DO ATENDIMENTO
        </v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.atividades }}
        </p>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatTexto(texto) {
      const textoSemColchetes = texto.replace(/\[|\]/g, ""); // Remover colchetes
      const textoSemQuebraDeLinha = textoSemColchetes.replace(/\\n/g, " ").replace(/"/g, ""); // Remover caracteres de nova linha (\n) e aspas
      const textoFormatado = textoSemQuebraDeLinha.replace(/\n/g, "<br>"); // Substituir quebras de linha por HTML <br>
      return textoFormatado.trim() || "- - -";
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
    };
  },
};
</script>

<style lang="scss" scoped></style>
